
/**
 * Returns whether or not this error was due to the network connection being
 * lost or the server being unreachable (for example, if the server is down).
 *
 * @param {Object} error the axior error object
 * @returns true if the error was due to a network or server connection error, false otherwise
 */
export function isConnectionError (error) {
  return !error.response || (typeof error.response.data === 'string' && error.response.data?.includes('ECONNREFUSED'))
}
